import {
    AccountingPeriodTypes,
    AccountTypes,
    ActivationDateTypes,
    AnalyticChooseTypes,
    AnalyticTypeProperties,
    AnalyticTypes,
    ApprovingFormatTypes,
    AssessmentCategoryCalculationTypes,
    AssessmentCategoryPurposes,
    AssessmentDocumentItems,
    AssessmentsMode,
    BdrExpenseGroup,
    BusinessMemberRoleTypes,
    CashFlowTypes,
    ContractAdditionalAgreementsTypes,
    ContractRuleGenerationType,
    ContractRuleNumberGenerationMode,
    ContractStageRuleConditionParameters, ContractSumTypes,
    ContractTypes,
    DealStageSemanticTypes,
    DepartmentTypes,
    DistributionProductTypes,
    EntitiesTypes,
    FieldTypePlacement,
    FieldTypes,
    FixedAssetStatuses,
    FundingClaimPaymentTypes,
    IntegrationAccountTypes,
    IntervalGroupTypes,
    InvoiceStageTypes,
    LoanDirectionTypes,
    LoanStatusTypes,
    LoanTypes,
    MarkTypes,
    MeasureTypes,
    NotificationsModesTypes,
    NotificationsTypes,
    OpiuExpenseGroup,
    OpiuIncomeGroup,
    OpiuTotalGroup,
    PayerAccountTypes,
    PaymentDocumentExtendedRights,
    PaymentDocumentTypes,
    PaymentTypes,
    PersonnelDocumentTypes,
    PossessionsTypes,
    ProbationUnits,
    PropertyMovementTypes,
    PurchaseStatus,
    RequisiteTypes,
    StageRuleApproverTypes,
    StageRuleConditionOperatorTypes,
    StageRuleConditionParameterTypes,
    TransactionCategoryTypes,
    TransactionImportFields,
    TransactionImportStatuses,
    TransactionImportTypeErrors,
    TransactionVerificationStatuses,
    VATTypes,
    WaitingForPurchaseTypes,
    ZupContractDocumentTypes,
    ZupDocumentTypes,
    ZupLoanDocumentTypes,
    ZupSalaryDocumentTypes,
} from '@/enums';

export const genders = {
    male: 'Мужской',
    female: 'Женский',
};

export const assessmentCategoryDirections = {
    in: 'Удержание',
    out: 'Начисление',
};

export const assessmentDocumentItems: Record<AssessmentDocumentItems, string> = {
    [AssessmentDocumentItems.PERSONAL_INCOME_TAX]: 'НДФЛ',
    [AssessmentDocumentItems.PFR]: 'ПФР',
    [AssessmentDocumentItems.FSS]: 'ФСС',
    [AssessmentDocumentItems.FSS_FOR_ACCIDENT]: 'ФСС (несч. сл.)',
    [AssessmentDocumentItems.FFOMS]: 'ФФОМС',
};

export const assessmentCategoryPurposes: Record<AssessmentCategoryPurposes, string> = {
    [AssessmentCategoryPurposes.OTHER]: 'Прочие доплаты и удержания',
    [AssessmentCategoryPurposes.PREMIUM]: 'Премиальная часть',
    [AssessmentCategoryPurposes.FIXED]: 'Фиксированная часть',
};

export const fieldTypes: Record<FieldTypes, string> = {
    [FieldTypes.STRING]: 'Строка',
    [FieldTypes.LIST]: 'Список',
    [FieldTypes.DATE]: 'Дата',
    [FieldTypes.NUMBER]: 'Число',
    [FieldTypes.CHECKBOX]: 'Флажок',
    [FieldTypes.LINK]: 'Ссылка',
    [FieldTypes.BUSINESS_REQUISITE]: 'Реквизит бизнеса',
    [FieldTypes.BUSINESS_ACCOUNT]: 'Счёт бизнеса',
};

export const fieldTypePlacement: Record<FieldTypePlacement, string> = {
    [FieldTypePlacement.CONDITIONS_TAB]: 'Условия',
    [FieldTypePlacement.MAIN_DATA_BLOCK]: 'Блок с основными данными',
};

export const assessmentCategoryCalculationTypes: Record<AssessmentCategoryCalculationTypes, string> = {
    [AssessmentCategoryCalculationTypes.FORMULA]: 'Расчитывается',
    [AssessmentCategoryCalculationTypes.MANUAL]: 'Вводится вручную',
    [AssessmentCategoryCalculationTypes.FIXED]: 'Фиксированный',
};

export const probationUnits: Record<ProbationUnits, string> = {
    [ProbationUnits.MONTH]: 'мес.',
    [ProbationUnits.WEEK]: 'нед.',
    [ProbationUnits.DAY]: 'дн.',
};

export const documentTypes: Record<PersonnelDocumentTypes, string> = {
    [PersonnelDocumentTypes.RECRUITMENT]: 'Приём на работу',
    [PersonnelDocumentTypes.CHANGE]: 'Изменение данных',
    [PersonnelDocumentTypes.DISMISSAL]: 'Увольнение',
    [PersonnelDocumentTypes.VACATION]: 'Отпуск',
    [PersonnelDocumentTypes.SEPARATION_LEAVE]: 'Отпуск с последующим увольнением',
    [PersonnelDocumentTypes.VACATION_COMPENSATION]: 'Компенсация отпуска',
    [PersonnelDocumentTypes.SICK_LIST]: 'Больничный лист',
    [PersonnelDocumentTypes.PARENTAL_LEAVE]: 'Отпуск по уходу за ребёнком',
    [PersonnelDocumentTypes.DISMISSAL_VACATION_COMPENSATION]: 'Компенсация отпуска при увольнении'
};

export const paymentDocumentTypes: Record<PaymentDocumentTypes, string> = {
    [PaymentDocumentTypes.PAYROLL]: 'Выплата сотрудникам',
    [PaymentDocumentTypes.RECOVERY]: 'Взыскание',
    [PaymentDocumentTypes.TAX]: 'НДФЛ',
    [PaymentDocumentTypes.PFR]: 'ПФР',
    [PaymentDocumentTypes.FSS]: 'ФСС',
    [PaymentDocumentTypes.FFOMS]: 'ФФОМС',
    [PaymentDocumentTypes.PFR_FSS_FFOMS]: 'ПФР, ФСС, ФФОМС',
    [PaymentDocumentTypes.FSS_FOR_ACCIDENTS]: 'ФСС (несч. случ)',
};

export const paymentTypes: Record<PaymentTypes, string> = {
    [PaymentTypes.ADVANCE]: 'Аванс',
    [PaymentTypes.SALARY]: 'Зарплата',
    [PaymentTypes.VACATION]: 'Отпуск',
    [PaymentTypes.DISMISSAL]: 'Увольнение',
    [PaymentTypes.GPC]: 'Договор ГПХ',
    [PaymentTypes.SICK]: 'Больничный',
    [PaymentTypes.MATERNITY_LEAVE]: 'Декретный отпуск',
    [PaymentTypes.FEE]: 'Взнос',
    [PaymentTypes.UNUSUAL]: 'Нестандартная выплата',
    [PaymentTypes.BUSINESS_TRIP]: 'Командировка',
};

export const accountTypes: Record<AccountTypes, string> = {
    [AccountTypes.BANK]: 'Расчетный счет',
    [AccountTypes.CASH]: 'Наличные',
};

export const transactionCategoryTypes: Record<TransactionCategoryTypes, string> = {
    [TransactionCategoryTypes.INCOME]: 'Поступления',
    [TransactionCategoryTypes.OUTCOME]: 'Списания',
    [TransactionCategoryTypes.ASSETS]: 'Перемещения',
    [TransactionCategoryTypes.LIABILITIES]: 'Обязательства',
    [TransactionCategoryTypes.CAPITAL]: 'Капитал',
};

export const analyticTypes: Record<AnalyticTypes, string> = {
    [AnalyticTypes.ACTIVITY]: 'Направление',
    [AnalyticTypes.EMPLOYEE]: 'Сотрудник',
    [AnalyticTypes.DEAL]: 'Сделка',
    [AnalyticTypes.BUSINESS_GROUP_EMPLOYEE]: 'Сотрудник бизнес-группы',
    [AnalyticTypes.DIVIDEND_ALLOCATION]: 'Собственник',
    [AnalyticTypes.LOAN]: 'Договор кредита',
    [AnalyticTypes.CONTRACTOR]: 'Клиент',
    [AnalyticTypes.CONTRACTOR_PROJECT]: 'Проект',
};

export const analyticTypesProperties: Record<AnalyticTypeProperties, string> = {
    [AnalyticTypeProperties.ACTIVITY]: 'Направление',
    [AnalyticTypeProperties.EMPLOYEE]: 'Сотрудник',
    [AnalyticTypeProperties.DEAL]: 'Сделка',
    [AnalyticTypeProperties.BUSINESS_GROUP_EMPLOYEE]: 'Сотрудник бизнес-группы',
    [AnalyticTypeProperties.DIVIDEND_ALLOCATION]: 'Собственник',
    [AnalyticTypeProperties.LOAN]: 'Договор кредита',
    [AnalyticTypeProperties.CONTRACTOR]: 'Клиент',
};

export const dealStageSemanticTypes: Record<DealStageSemanticTypes, string> = {
    [DealStageSemanticTypes.NEW]: 'Начальный',
    [DealStageSemanticTypes.IN_WORK]: 'В работе',
    [DealStageSemanticTypes.SUCCESS]: 'Успешный',
    [DealStageSemanticTypes.FAILURE]: 'Неудачный',
};

export const requisiteTypes: Record<RequisiteTypes, string> = {
    [RequisiteTypes.IE]: 'ИП',
    [RequisiteTypes.LLC]: 'Юр.лицо',
    [RequisiteTypes.INDIVIDUAL]: 'Физ.лицо',
    [RequisiteTypes.GOVERNMENT]: 'Гос.орган',
    [RequisiteTypes.SELF_EMPLOYED]: 'Самозанятый',
};

export const cashFlowTypes: Record<CashFlowTypes, string> = {
    [CashFlowTypes.INVESTMENT]: 'Инвестиционная деятельность',
    [CashFlowTypes.FINANCIAL]: 'Финансовая деятельность',
    [CashFlowTypes.OPERATIONAL]: 'Операционная деятельность',
};

export const cashFlowShortTypes: Record<CashFlowTypes, string> = {
    [CashFlowTypes.INVESTMENT]: 'Инвестиционная',
    [CashFlowTypes.FINANCIAL]: 'Финансовая',
    [CashFlowTypes.OPERATIONAL]: 'Операционная',
};

export const transactionCategorySecondaryTypes: Record<any, string> = {
    [TransactionCategoryTypes.INCOME]: 'Статьи поступлений',
    [TransactionCategoryTypes.OUTCOME]: 'Статьи списаний',
    [TransactionCategoryTypes.ASSETS]: 'Статьи перемещений',
};

export const analyticChooseTypes: Record<AnalyticChooseTypes, string> = {
    [AnalyticChooseTypes.NOT_USE]: 'Не используется',
    [AnalyticChooseTypes.AT_FILLING]: 'Указывается при заполнении',
    [AnalyticChooseTypes.CERTAIN]: 'Выбор конкретного элемента',
};

export const paymentDocumentExtendedRights: Record<PaymentDocumentExtendedRights, string> = {
    [PaymentDocumentExtendedRights.CAN_ALL]: 'Все',
    [PaymentDocumentExtendedRights.ONLY_1C_ZUP]: 'Ведомости 1С:ЗУП',
    [PaymentDocumentExtendedRights.ONLY_PAYMENTS_CREATED_BY_HAND]: 'Ведомости созданные вручную',
};

export const approvingFormatTypes: Record<ApprovingFormatTypes, string> = {
    [ApprovingFormatTypes.BY_PAYMENT]: 'Заявка целиком',
    [ApprovingFormatTypes.BY_PAYMENT_ITEM]: 'Построчно',
};

export const stageRuleConditionOperatorTypes: Record<StageRuleConditionOperatorTypes, string> = {
    [StageRuleConditionOperatorTypes.EQ]: 'Равно',
    [StageRuleConditionOperatorTypes.GT]: 'Больше',
    [StageRuleConditionOperatorTypes.LT]: 'Меньше',
    [StageRuleConditionOperatorTypes.NEQ]: 'Не равно',
};

export const stageRuleConditionParameterTypes: Record<StageRuleConditionParameterTypes, string> = {
    [StageRuleConditionParameterTypes.ANALYTICS]: 'Аналитика',
    [StageRuleConditionParameterTypes.APPLICANT]: 'Заявитель',
    [StageRuleConditionParameterTypes.PAYER_ACCOUNT]: 'Счет плательщика',
    [StageRuleConditionParameterTypes.PAYER_ACCOUNT_TYPE]: 'Тип счета плательщика',
    [StageRuleConditionParameterTypes.PAYER_REQUISITE]: 'Реквизит плательщика',
    [StageRuleConditionParameterTypes.SUM_AMOUNT]: 'Сумма',
    [StageRuleConditionParameterTypes.TRANSACTION_CATEGORY]: 'Статья учета',
};

export const stageRuleApproverTypes: Record<StageRuleApproverTypes, string> = {
    [StageRuleApproverTypes.EMPLOYEE]: 'Сотрудник',
    [StageRuleApproverTypes.RESPONSIBLE_FOR_PROJECT]: 'Ответственный за проект',
    [StageRuleApproverTypes.ACTIVITY_HEAD]: 'Руководитель направления',
    [StageRuleApproverTypes.BY_ORGCHART]: 'По структуре компании',
    [StageRuleApproverTypes.OTHER_USER]: 'Пользователь',
    [StageRuleApproverTypes.RESPONSIBLE_FOR_DEAL]: 'Ответственный за сделку',
    [StageRuleApproverTypes.INITIATOR]: 'Инициатор',
    [StageRuleApproverTypes.NOBODY]: 'Никто'
};

export const payerAccountTypes: Record<PayerAccountTypes, string> = {
    [PayerAccountTypes.BANK]: 'Расчетный счет',
    [PayerAccountTypes.CASH]: 'Наличные',
};

export const departmentTypes: Record<DepartmentTypes, string> = {
    [DepartmentTypes.ADMINISTRATIVE]: 'Административный отдел',
    [DepartmentTypes.COMMERCIAL]: 'Коммерческий отдел',
    [DepartmentTypes.PRODUCTION]: 'Производство',
};

export const departmentCategories: Record<DepartmentTypes, string> = {
    [DepartmentTypes.ADMINISTRATIVE]: 'Административный',
    [DepartmentTypes.COMMERCIAL]: 'Коммерческий',
    [DepartmentTypes.PRODUCTION]: 'Производственный',
};

export const loanTypes: Record<LoanTypes | LoanDirectionTypes, string> = {
    [LoanTypes.EMPLOYEE]: 'Кредит/займ сотруднику',
    [LoanTypes.CONTRACTOR]: 'Кредит/займ бизнесу',
    in: 'Полученные кредиты',
    out: 'Выданные кредиты',
};

export const loanStatusTypes: Record<LoanStatusTypes, string> = {
    [LoanStatusTypes.ACTIVE]: 'Действует',
    [LoanStatusTypes.COMPLETED]: 'Завершен',
};

export const invoiceStageTypes: Record<InvoiceStageTypes, string> = {
    [InvoiceStageTypes.NOT_PAID]: 'Не оплачен',
    [InvoiceStageTypes.PARTIALLY_PAID]: 'Частично оплачен',
    [InvoiceStageTypes.PAID]: 'Оплачен',
    [InvoiceStageTypes.REVOKED]: 'Аннулирован',
};

export const notificationsTypes: Record<NotificationsTypes, string> = {
    [NotificationsTypes.TELEGRAM]: 'Телеграм',
    [NotificationsTypes.EMAIL]: 'Почта',
    [NotificationsTypes.SITE]: 'Сайт',
};

export const notificationsModesTypes: Record<NotificationsModesTypes, string> = {
    [NotificationsModesTypes.DISABLED]: 'Отключить',
    [NotificationsModesTypes.IMMEDIATELY]: 'Уведомлять сразу',
    [NotificationsModesTypes.POSTPONED]: 'Уведомлять 3 раза в день',
};

export const measureTypes: Record<MeasureTypes, string> = {
    [MeasureTypes.UNITS]: 'шт',
    [MeasureTypes.PERCENTS]: '%',
};

export const vatTypes: Record<VATTypes, string> = {
    [VATTypes.NO_VAT]: 'Без НДС',
    [VATTypes.VAT_20]: 'НДС 20%',
    [VATTypes.SELF_EMPLOYED_TAX]: 'НПД',
};

export const businessMemberRoleTypes: Record<BusinessMemberRoleTypes, string> = {
    [BusinessMemberRoleTypes.OWNER]: 'Владелец бизнеса',
    [BusinessMemberRoleTypes.USER]: 'Пользователь',
    [BusinessMemberRoleTypes.INACTIVE]: 'Не активен',
};

export const intervalGroupTypes: Record<IntervalGroupTypes, string> = {
    [IntervalGroupTypes.DAY]: 'По дням',
    [IntervalGroupTypes.MONTH]: 'По месяцам',
    [IntervalGroupTypes.WEEK]: 'По неделям',
    [IntervalGroupTypes.QUARTER]: 'По кварталам',
};

export const fundingClaimTypes: Record<FundingClaimPaymentTypes, string> = {
    [FundingClaimPaymentTypes.SIMPLE]: 'Простая',
    [FundingClaimPaymentTypes.MULTIPLE]: 'Несколько оплат',
};

export const purchaseStatus: Record<PurchaseStatus, string> = {
    [PurchaseStatus.ORIGINAL_RECEIVED]: 'Получен оригинал',
    [PurchaseStatus.SCAN_RECEIVED]: 'Получен скан',
    [PurchaseStatus.RECEIVED_VIA_EDO]: 'Получен через ЭДО',
    [PurchaseStatus.NOT_SIGNED]: 'Не подписан',
    [PurchaseStatus.REVOKED]: 'Аннулирован',
};

export const entitiesTypes: Record<EntitiesTypes, string> = {
    [EntitiesTypes.TRANSACTION]: 'Операции',
    [EntitiesTypes.FUNDING_CLAIM_PAYMENT]: 'Заявки',
    [EntitiesTypes.CONTRACT]: 'Договоры',
    [EntitiesTypes.INVOICE]: 'Счета',
    [EntitiesTypes.CONTRACT_MODIFICATION]: 'Доп соглашения',
    [EntitiesTypes.RECEIPT_DOCUMENT]: 'Закупки имущества',
    [EntitiesTypes.CONTRACTOR_BANK_ACCOUNT]: 'Расчетные счета',
    [EntitiesTypes.LOAN]: 'Кредиты',
    [EntitiesTypes.PURCHASE]: 'Закупки',
};

export const waitingForPurchaseTypes: Record<WaitingForPurchaseTypes, string> = {
    [WaitingForPurchaseTypes.WAITING_FOR_SHIPMENT]: 'Ожидается отгрузка по сделке',
    [WaitingForPurchaseTypes.SHIPMENT_WAS_SHIPPED]: 'Отгружена',
};

export const opiuGroup: Record<any, string> = {
    [OpiuIncomeGroup.CUSTOMERS_REVENUE]: 'Выручка от клиентов',
    [OpiuIncomeGroup.INCOMES_LESS_EBITDA]: 'Доходы ниже EBITDA',
    [OpiuExpenseGroup.PRODUCTION_VARIABLE]: 'Переменные производственные',
    [OpiuExpenseGroup.PERMANENT_PRODUCTION]: 'Постоянные производственные',
    [OpiuExpenseGroup.COMMERCIAL]: 'Коммерческие',
    [OpiuExpenseGroup.ADMINISTRATIVE]: 'Административные',
    [OpiuExpenseGroup.EXPENSES_LESS_EBITDA]: 'Расходы ниже EBITDA',
    [OpiuExpenseGroup.EXPENSES_LESS_NET_INCOME]: 'Расходы ниже чистой прибыли',
    [OpiuIncomeGroup.UNALLOCATED_INCOME]: 'Доходы без группировки',
    [OpiuExpenseGroup.UNALLOCATED_EXPENSE]: 'Расходы без группировки',
    [OpiuExpenseGroup.DIRECT_DEAL_PRODUCTION_VARIABLES]: 'Прямые расходы на сделку',
    [`fot_${DepartmentTypes.COMMERCIAL}`]: 'ФОТ коммерческого отдела',
    [`fot_${DepartmentTypes.ADMINISTRATIVE}`]: 'ФОТ административного отдела',
    [`fot_${DepartmentTypes.PRODUCTION}`]: 'ФОТ производства',
    [OpiuExpenseGroup.INCOME_TAX]: 'Налог на прибыль',
    [OpiuExpenseGroup.GENERAL_PRODUCTION]: 'Общепроизводственные',
    [OpiuExpenseGroup.AMORTIZATION]: 'Амортизация',
    [BdrExpenseGroup.PHOT_COMMERCIAL_DIVISION]: 'ФОТ коммерческого отдела',
    [BdrExpenseGroup.PHOT_ADMINISTRATION_DIVISION]: 'ФОТ административного отдела',
    [BdrExpenseGroup.PHOT_PRODUCTION]: 'ФОТ производства',
};

export const opiuFotType = {
    fixed: 'Постоянная часть ЗП',
    premium: 'Премиальная часть ЗП',
    incomeTax: 'НДФЛ',
    deposit: 'Взносы в фонды',
};

export const opiuTotalGroup: Record<OpiuTotalGroup, string> = {
    [OpiuTotalGroup.MARGINAL_PROFIT]: 'Маржинальная прибыль',
    [OpiuTotalGroup.GROSS_PROFIT]: 'Валовая прибыль общая',
    [OpiuTotalGroup.EBITDA]: 'Операционная прибыль (EBITDA)',
    [OpiuTotalGroup.NET_INCOME]: 'Чистая прибыль',
    [OpiuTotalGroup.UNALLOCATED]: 'Нераспределённая прибыль',
};

export const accountingPeriodTypes: Record<AccountingPeriodTypes, string> = {
    [AccountingPeriodTypes.NONE]: 'Не учитывается',
    [AccountingPeriodTypes.BY_ACCOUNTING_PERIOD]: 'Указать месяц',
    [AccountingPeriodTypes.BY_DEAL]: 'По Сделке',
    [AccountingPeriodTypes.BY_DOCUMENT]: 'Отдельным документом',
    [AccountingPeriodTypes.BY_PAYMENT_DATE]: 'По дате оплаты',
    [AccountingPeriodTypes.ADVANCE]: 'Аванс',
};

export const activationDateTypes: Record<ActivationDateTypes, string> = {
    [ActivationDateTypes.BY_DATE]: 'Указать дату',
    [ActivationDateTypes.FROM_AMENDMENT_DATE]: 'С даты документа (доп.соглашение)',
};

export const propertyMovementTypes: Record<PropertyMovementTypes, string> = {
    [PropertyMovementTypes.ADMISSION]: 'Поступление',
    [PropertyMovementTypes.MOVING]: 'Перемещение',
};

export const assessmentsMode: Record<AssessmentsMode, string> = {
    [AssessmentsMode.WITH_TAX]: 'С учетом НДФЛ',
    [AssessmentsMode.WITHOUT_TAX]: 'Без учета НДФЛ',
};

export const distributionProductTypes: Record<DistributionProductTypes, string> = {
    [DistributionProductTypes.AT_COST]: 'По себестоимости',
    [DistributionProductTypes.IN_THE_NUMBER_OF]: 'По количеству',
    [DistributionProductTypes.IS_NOT_DISTRIBUTED]: 'Не распределяется',
};

export const possessionsDocumentTypes: Record<PossessionsTypes, string> = {
    [PossessionsTypes.MOVEMENT_PRODUCT]: 'Перемещение товара',
    [PossessionsTypes.MOVEMENT_FIXED_ASSETS]: 'Перемещение основного средства',
    [PossessionsTypes.RECEIPT_PRODUCT]: 'Поступление товара',
    [PossessionsTypes.RECEIPT_FIXED_ASSETS]: 'Поступление основного средства',
};

export const zupSalaryDocumentTypes: Record<ZupSalaryDocumentTypes, string> = {
    [ZupSalaryDocumentTypes.BANK_PAY_SLIP]: 'Ведомость на выплату зарплаты в банк',
    [ZupSalaryDocumentTypes.CASH_PAY_SLIP]: 'Ведомость на выплату зарплаты в кассу',
    [ZupSalaryDocumentTypes.SALARY_ASSESSMENT]: 'Начисление зарплаты',
    [ZupSalaryDocumentTypes.TRANSFER_PAY_SLIP]: 'Ведомость на выплату зарплаты перечислением',
    [ZupSalaryDocumentTypes.PREMIUM]: 'Премия',
    [ZupSalaryDocumentTypes.ASSESSMENT_FOR_FIRST_HALF_OF_MONTH]: 'Начисление за первую половину месяца',
};

export const zupContractDocumentTypes: Record<ZupContractDocumentTypes, string> = {
    [ZupContractDocumentTypes.COMPLETED_WORK_CERTIFICATE]: 'Акт приёмки выполненных работ',
    [ZupContractDocumentTypes.AUTHOR_ORDER_CONTRACT]: 'Договор авторского заказа',
    [ZupContractDocumentTypes.SERVICE_CONTRACT]: 'Договор (работы, услуги)',
    [ZupContractDocumentTypes.PERFORMANCE_LIST]: 'Исполнительный лист',
    [ZupContractDocumentTypes.WITHHOLD_TO_ACCOUNT_FOR_OTHER_TRANSACTIONS]: 'Удержание в счёт расчётов по прочим операциям',
};

export const zupLoanDocumentTypes: Record<ZupLoanDocumentTypes, string> = {
    [ZupLoanDocumentTypes.LOAN]: 'Договор займа сотруднику',
};

export const zupDocumentTypes: Record<ZupDocumentTypes, string> = {
    [ZupDocumentTypes.ABSENCE]: 'Прогул (неявка)',
    [ZupDocumentTypes.BUSINESS_TRIP]: 'Командировка',
    [ZupDocumentTypes.CHANGE]: 'Кадровый перевод',
    [ZupDocumentTypes.COMPENSATORY_HOLIDAY]: 'Отгул',
    [ZupDocumentTypes.DISMISSAL]: 'Увольнение',
    [ZupDocumentTypes.RECRUITMENT]: 'Приём на работу',
    [ZupDocumentTypes.SICK_LIST]: 'Больничный лист',
    [ZupDocumentTypes.VACATION]: 'Отпуск',
    [ZupDocumentTypes.PARENTAL_LEAVE]: 'Отпуск по уходу за ребёнком',
    [ZupDocumentTypes.RETURN_FROM_PARENTAL_LEAVE]: 'Возврат из отпуска по уходу за ребёнком',
    [ZupDocumentTypes.VACATION_SCHEDULE]: 'График отпусков',
    [ZupDocumentTypes.VACATION_TRANSFER]: 'Перенос отпуска',
    [ZupDocumentTypes.VACATION_WITHOUT_PAY]: 'Отпуск без сохранения оплаты',
    [ZupDocumentTypes.CHANGE_LIST]: 'Кадровый перевод списком',
    [ZupDocumentTypes.RECRUITMENT_LIST]: 'Кадровый приём списком',
    [ZupDocumentTypes.VACATION_WITHOUT_PAY]: 'Отпуск без сохранения оплаты',
};

export const markTypes: Record<MarkTypes, string> = {
    [MarkTypes.EXCLAMATION]: '!',
    [MarkTypes.QUESTION]: '?',
};

export const contractTypes: Record<ContractTypes, string> = {
    [ContractTypes.EXPENSE]: 'Расходные договоры',
    [ContractTypes.INCOME]: 'Доходные договоры',
    [ContractTypes.INTERNAL]: 'Внутренние документы',
};

export const contractAdditionalAgreementsTypes: Record<ContractAdditionalAgreementsTypes, string> = {
    [ContractAdditionalAgreementsTypes.MODIFICATION]: 'Изменение договора',
    [ContractAdditionalAgreementsTypes.TERMINATION]: 'Расторжение договора',
};

export const contractRuleNumberGenerationMode: Record<ContractRuleNumberGenerationMode, string> = {
    [ContractRuleNumberGenerationMode.FIRST_FREE]: 'Первый свободный номер',
    [ContractRuleNumberGenerationMode.LAST_NUMBER_INCREASED]: 'Последний номер + 1',
};

export const contractRuleGenerationType: Record<ContractRuleGenerationType, string> = {
    [ContractRuleGenerationType.NUMBERS]: 'Цифры по порядку',
    [ContractRuleGenerationType.PREFIX_AND_NUMBERS]: 'Префикс и цифры',
    [ContractRuleGenerationType.DISABLE]: 'Отключить генерацию номера',
};

export const transactionImportStatuses: Record<TransactionImportStatuses, string> = {
    [TransactionImportStatuses.NEW]: 'Новый',
    [TransactionImportStatuses.IN_PROCESS]: 'В процессе',
    [TransactionImportStatuses.PROCESSED]: 'Импорт завершен',
};
export const transactionImportTypeErrors: Record<TransactionImportTypeErrors, string> = {
    [TransactionImportTypeErrors.DUPLICATE]: 'Дубликат',
    [TransactionImportTypeErrors.CRITICAL]: 'Критичный',
    [TransactionImportTypeErrors.NOT_CRITICAL]: 'Не критичный',
};

export const transactionImportFields: Record<TransactionImportFields, string> = {
    [TransactionImportFields.DATE]: 'Дата',
    [TransactionImportFields.DESCRIPTION]: 'Назначение платежа',
    [TransactionImportFields.NUMBER]: 'Номер платежного поручения',
    [TransactionImportFields.CATEGORY_NAME]: 'Статья учёта',
    [TransactionImportFields.ACCOUNTING_PERIOD]: 'Период учёта',
    [TransactionImportFields.CONTRACTOR_REQUISITE_NAME]: 'Наименование реквизита',
    [TransactionImportFields.CONTRACTOR_REQUISITE_INN]: 'ИНН контрагента',
    [TransactionImportFields.CONTRACTOR_REQUISITE_KPP]: 'КПП контрагента',
    [TransactionImportFields.CONTRACTOR_REQUISITE_ACCOUNT]: 'Счёт контрагента',
    [TransactionImportFields.CONTRACTOR_REQUISITE_BIC]: 'БИК банка контрагента',
    [TransactionImportFields.CONTRACTOR_REQUISITE_BANK_NAME]: 'Наименование банка контрагента',
    [TransactionImportFields.CONTRACTOR_REQUISITE_BANK_ACCOUNT]: 'К/С банка контрагента',
    [TransactionImportFields.ANALYTIC_DEAL_NAME]: 'Сделка',
    [TransactionImportFields.ANALYTIC_LOAN_NAME]: 'Договор кредита',
    [TransactionImportFields.ANALYTIC_ACTIVITY_NAME]: 'Направление',
    [TransactionImportFields.ANALYTIC_CONTRACTOR_NAME]: 'Клиент',
    [TransactionImportFields.ANALYTIC_EMPLOYEE_FULL_NAME]: 'Сотрудник',
    [TransactionImportFields.ANALYTIC_DIVIDEND_ALLOCATION_OWNER]: 'Собственник',
    [TransactionImportFields.ANALYTIC_BUSINESS_GROUP_EMPLOYEE_FULL_NAME]: 'Сотрудник бизнес-группы',
    [TransactionImportFields.AMOUNT]: 'Поступление/Списание',
    [TransactionImportFields.POSITIVE_AMOUNT]: 'Поступление',
    [TransactionImportFields.NEGATIVE_AMOUNT]: 'Списание',
    [TransactionImportFields.BUSINESS_REQUISITE_INN]: 'ИНН реквизита бизнеса',
    [TransactionImportFields.BUSINESS_REQUISITE_KPP]: 'КПП реквизита бизнеса',
    [TransactionImportFields.BUSINESS_ACCOUNT]: 'Счёт бизнеса',
    [TransactionImportFields.BUSINESS_ACCOUNT_BANK_BIC]: 'БИК банка бизнеса',
    [TransactionImportFields.BUSINESS_REQUISITE_NAME]: 'Реквизит бизнеса',
};

export const fixedAssetStatuses: Record<FixedAssetStatuses, string> = {
    [FixedAssetStatuses.NOT_COMMISSIONED]: 'Не введено в эксплуатацию',
    [FixedAssetStatuses.IN_OPERATION]: 'В эксплуатации',
    [FixedAssetStatuses.DEPRECATED]: 'Самортизировано',
    [FixedAssetStatuses.CANCELLATION]: 'Списано досрочно',
};

export const integrationAccountTypes: Record<IntegrationAccountTypes, string> = {
    [IntegrationAccountTypes.ZEN_MONEY]: 'Zenmoney',
};

export const contractStageRuleConditionParameters: Record<ContractStageRuleConditionParameters, string> = {
    [ContractStageRuleConditionParameters.CONTRACT_TYPE]: 'Тип договора',
    [ContractStageRuleConditionParameters.CONTRACT_VERSION]: 'Версия документа',
};

export const transactionVerificationStatuses: Record<TransactionVerificationStatuses, string> = {
    [TransactionVerificationStatuses.NEW]: 'Новый',
    [TransactionVerificationStatuses.IN_PROCESS]: 'В обработке',
    [TransactionVerificationStatuses.PROCESSED]: 'Успешно завершён',
    [TransactionVerificationStatuses.ERROR]: 'Ошибка',
};

export const contractSumTypes: Record<ContractSumTypes, string> = {
    [ContractSumTypes.FRAME_CONTRACT_REQUESTS]: 'По заявкам к рамочному договору',
    [ContractSumTypes.FIXED_SUM]: 'Фиксированная сумма',
    [ContractSumTypes.REGULAR_PAYMENTS]: 'Регулярные оплаты',
    [ContractSumTypes.NOT_RECORDED]: 'Не зафиксировано'
};
